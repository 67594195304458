<template>
  <div class="six-right-layout">
    <display
      :key="index"
      :class="gridItem.css"
      :parent-category-id="gridItem.parentCategoryId"
      v-for="(gridItem, index) in gridLayout">
    </display>
  </div>
</template>

<script>
  import DigitalSignageMixin from '../../../mixin/digital-signage'

  export default {
    watch: {
      category: {
        deep: true,
        immediate: true,
        handler() {
          this.cssClasses = [
            'row-start-1 row-end-3 col-start-2 col-end-4',
            'row-start-1 row-end-1 col-start-1 col-end-1',
            'row-start-2 row-end-2 col-start-1 col-end-2',
            'row-start-3 row-end-3 col-start-1 col-end-2',
            'row-start-3 row-end-3 col-start-2 col-end-2',
            'row-start-3 row-end-3 col-start-3 col-end-3',
          ]
          this.prepareDigitalSignageObjects(6)
        }
      }
    },
    mixins: [
      DigitalSignageMixin
    ],
    inject: [
      '$api',
    ]
  }
</script>

<style lang="scss" scoped>
  .six-right-layout {
    @apply
      w-full
      h-full
      md:grid
      grid-rows-3
      grid-cols-3;
  }
</style>